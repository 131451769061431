<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable max-len -->
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10 2C9.44772 2 9 2.44772 9 3V18C9 18.5523 9.44772 19 10 19H11C11.5523 19 12 18.5523 12 18V3C12 2.44772 11.5523 2 11 2H10ZM5 6C4.44772 6 4 6.44772 4 7V18C4 18.5523 4.44772 19 5 19H6C6.55228 19 7 18.5523 7 18V7C7 6.44772 6.55228 6 6 6H5ZM14.1479 10.2514C13.9007 9.75754 14.1007 9.15678 14.5945 8.90957L15.4888 8.46196C15.9826 8.21475 16.5834 8.41471 16.8306 8.90858L20.9738 17.1859C21.2211 17.6797 21.0211 18.2805 20.5272 18.5277L19.633 18.9753C19.1391 19.2225 18.5384 19.0226 18.2912 18.5287L14.1479 10.2514ZM22 21C22 20.4477 21.5523 20 21 20H3C2.44772 20 2 20.4477 2 21C2 21.5523 2.44772 22 3 22H21C21.5523 22 22 21.5523 22 21Z"
                :fill="color"
            />
            <!-- eslint-enable max-len -->
        </template>
    </AppIcon>
</template>
